<template>
    <modal ref="modalEstadistica" :titulo="titulo" no-aceptar no-cancelar cerrar tamano="modal-xl">
        <div class="row mx-0">
            <div class="col-12 px-0 pt-3">
                <el-table :data="tableData" style="width: 100%">
                    <el-table-column prop="producto" label="Producto" width="245" />
                    <el-table-column prop="cantidad" label="Cantidad" width="85" align="right" header-align="center" />
                    <el-table-column prop="total_valor" label="Valor" align="right" header-align="center" />
                    <el-table-column prop="id_admin_pedido" label="Pedido" />
                    <el-table-column prop="entrega_fecha" label="Fecha Pedido" />
                    <el-table-column prop="leechero_nombre" label="Vendedor" />
                    <el-table-column prop="motivo" label="Motivo" />
                    <el-table-column prop="cedis" label="Cedis" />
                </el-table>
            </div>
        </div>
    </modal>
</template>

<script>
import Estadisticas from '~/services/estadisticas_devoluciones_eliminaciones'
export default {
    props: {
        titulo: {
            type: String,
            default: ''
        }
    },
    data(){
        return {
            tableData: [],
            cargandoInforme: true,
            params: {}
        }
    },
    methods: {
        toggle(params){
            this.params = params
            this.verMasProductos()
            this.$refs.modalEstadistica.toggle();
        },
        async verMasProductos(){
            try {
                this.cargandoInforme = false

                const {data} = await Estadisticas.verMasProductos(this.params)
                data.datos.map(el => {
                    el.cantidad = this.agregarSeparadoresNumero(el.cantidad)
                    el.total_valor = this.separadorNumero(el.total_valor)
                    el.entrega_fecha = this.formatearFecha(el.entrega_fecha,'D MMM YYYY')
                })
                this.tableData = data.datos

            } catch (e){
                this.error_catch(e)
            } finally{
                this.cargandoInforme = true
            }
        },
    }
}
</script>
<style lang="scss" scoped>
</style>
